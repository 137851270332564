import React from 'react';
import PropTypes from 'prop-types';

export function TabContainer({ children, activeTab }) {
  const renderChildren = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, Object.assign({},
      child.props,
      {
        id: 'child-' + index,
        label: child.props.label,
        activeState: activeTab === index
      }
    ));
  });

  return (
    <div className="tab-container">
      {renderChildren}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node
};
