const PairConnectorArrow = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18L24 9L10 0V6H0V12H10V18Z"
      transform="translate(0 3)"
    />
  </svg>
);

export default PairConnectorArrow;
