
export const CHANGE_ASSOCIATION_SUCCESS = 'CHANGE_ASSOCIATION_SUCCESS';
export const CHANGE_ASSOCIATION_REJECTED = 'CHANGE_ASSOCIATION_REJECTED';
export const CHANGE_ASSOCIATION_PENDING = 'CHANGE_ASSOCIATION_PENDING';

export const GET_EVENTS_PENDING = 'GET_EVENTS_PENDING';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_REJECTED = 'GET_EVENTS_REJECTED';
export const GET_EVENTS_ABORTED = 'GET_EVENTS_ABORTED';

export const SAVE_PARAMS = 'SAVE_PARAMS';
