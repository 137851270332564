import { useState } from 'react';
import { Button, useAddMessage } from 'react-md';
import { PairConnectorArrow } from '../../assets/react-icons';
import { EventCard, Selectable } from '..';
import { remove, isEmpty, sortByDate } from '../../utils';
import './Unassociated.scss';

export default function Unassociated({
  confirmAssociation,
  data
}) {
  const addMessage = useAddMessage();
  const [externalEventsSelected, setExternalEventsSelected] = useState([]);
  const [internalEventSelected, setInternalEventSelected] = useState(null);

  function isDisabled() {
    return !(!!internalEventSelected && externalEventsSelected.length >= 1);
  }

  function handleAssociate() {
    const internalEvent = {
      ...internalEventSelected,
      externalEvents: externalEventsSelected
    };

    addMessage({
      children: 'Associated pair created'
    });

    confirmAssociation([internalEvent]);
  }

  function handleInternalEventClicked(event, active) {
    setInternalEventSelected(active ? event : null);
  }

  function handleExternalEventClicked(event, active) {
    setExternalEventsSelected(prevState => active ? [event, ...prevState] : remove(prevState, event, 'id'));
  }

  function setInactive(id) {
    const selected = internalEventSelected;

    return !!selected ? (selected.id !== id) : false;
  }

  return (
    <div id="unassociated">
      <div className="wrapper">
        {!isEmpty(data.externalEvents) ?
          <>
            <div className="top-controls">
              <span className="details">
                {externalEventsSelected.length} external events will be associated
              </span>
              <Button
                disabled={isDisabled()}
                onClick={handleAssociate}
                theme="primary"
                themeType="contained"
                className="confirm-button"
              >
                Associate
              </Button>
            </div>
            <div className="unassociated-content">
              <div>
                <h3 className="unassociated-label">External Events</h3>
                {
                  sortByDate(data.externalEvents, 'eventTimestamp').map((event, key) => {
                    return (
                      <Selectable
                        key={key}
                        onClick={handleExternalEventClicked}
                        data={event}
                      >
                        <EventCard
                          event={event.eventName}
                          landmark={event.landmarkName}
                          timestamp={event.eventTimestamp}
                          source={event.source}
                          passes={event.passCount}
                          identifier={event.eventIdentifier}
                        />
                      </Selectable>
                    );
                  })
                }
              </div>
              <div className="center">
                <PairConnectorArrow
                  color="#c4c4c4"
                  height="28"
                  width="28"
                />
              </div>
              <div>
                <h3 className="unassociated-label">Internal Events</h3>
                {sortByDate(data.events, 'from').map((event, key) => (
                  <Selectable
                    key={key}
                    data={event}
                    idKey={key}
                    onClick={handleInternalEventClicked}
                    inactive={setInactive(event.id)}
                  >
                    <EventCard
                      event={event.eventName}
                      live={event.live}
                      landmark={event.landmarkName}
                      eventFrom={event.from}
                    />
                  </Selectable>
                ))}
              </div>
            </div>
          </>
          :
          <div className="search-error">
            <h2 className="no-results">
              There's nothing left to associate... I've got to say it was a good day.
            </h2>
          </div>
        }
      </div>
    </div>
  );
}
