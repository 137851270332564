import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';
import _ from 'lodash';
import moment from 'moment';
import { checkPermissions } from './rbac';

export * from './rbac';

const cookies = new Cookies();

const ajax = axios.create({
  baseURL: process.env.REACT_APP_CORE_USER_API_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});

// get cookie by name
export function getCookie(name) {
  return cookies.get(name);
}

/**
 * Update auth cookie
 * @return {promise} promise
 */
export function refreshSession() {
  return new Promise((resolve, reject) => {
    const cookie = getCookie('86553b39');

    if (cookie) {
      resolve(ajax.post('authenticate?refreshCookie=true'));
    } else {
      reject(`Can not refresh session because current cookie expired`);
    }
  });
}

/**
 * Sign out user and redirect to suite login
 * @param {string} url params to add to sign-out url
 */
export function signOut(params = '') {
  return window.location.replace(`${process.env.REACT_APP_SUITE_URL + 'sign-out' + params}`);
}

export function checkForGetError(resp, successType, errorType, dispatch) {
  const data = resp.data;

  if (data.hasOwnProperty('errors') && data.errors[0].message.match(/SELECT/)) {
    dispatch({
      type: errorType,
      payload: 'Unexpected server error. Please contact administrator.'
    });
  } else if (data.hasOwnProperty('errors') && data.errors[0].message.match(/auth/)) {
    console.error('error calling auth endpoint');

    dispatch({
      type: errorType,
      payload: 'Authentication failed. Please log back in.'
    });

    setTimeout(() => signOut(), 4000);
  } else {
    dispatch({
      type: successType,
      payload: data.data.EventAnalytics
    });
  }
}

function checkForEnvMatch() {
  if (getCookie('86553b39')) {
    const authUser = getAuthUser();
    return authUser.ne === process.env.REACT_APP_ENV_TAG && authUser.g[0].r === 'GLOBAL_ADMIN';
  } else {
    return false;
  }
}

/**
 * Check if user is authenticated
 * @return {boolean} - if cookie is valid, env matches, and user has permissions true : false
 */
export const checkAuth = () => {
  const authCookie = getCookie('86553b39');
  const hasAppAccess = checkPermissions('prepaid_pass_manager');

  return !!authCookie && checkForEnvMatch() && hasAppAccess;
};

/**
 * Get the auth user from the cookie and token
 * @return {object} auth user object
 */
export function getAuthUser() {
  const authCookie = getCookie('86553b39');

  if (authCookie) {
    return jwtDecode(authCookie)?.payload;
  }

  return null;
}

export function getPermissions() {
  const permCookie = getCookie('perm');

  if (permCookie) {
    return jwtDecode(permCookie)?.payload;
  }
}

export function getSessionTime() {
  const authCookie = getCookie('86553b39');

  if (authCookie) {
    const iat = jwtDecode(authCookie)?.iat;

    if (iat) {
      return Math.abs((new Date(iat * 1000).getTime() - new Date().getTime()) / 1000 / 60);
    }
  }
}

export function sortByDate(events, dateKey) {
  return _.sortBy(events, (o) => new moment(o[dateKey]).format('YYYYMMDD'));
}

export function isEmpty(array) {
  return _.isEmpty(array);
}

export function remove(array, element, key = 'id') {
  return _.reject(array, { [key]: element[key] });
}

export function calcTotalPasses(eventsArray) {
  const eventNames = [];
  let total = 0;

  eventsArray.forEach((record) => {
    if (eventNames.includes(record.eventName)) {
      return;
    }

    eventNames.push(record.eventName);

    return total += parseInt(record.passCount, 10);
  });

  return total;
}

export const defaultSearchParams = {
  eventName: '',
  landmarkName: '',
  dateFrom: moment().startOf('day').toISOString(),
  dateTo: moment().add(3, 'days').toISOString(),
  source: ''
};

export function createEventGroupById(internalEvents, externalEvents) {
  return internalEvents.map(parent => ({ ...parent, externalEvents: [...externalEvents.filter(child => parent.id === child.eventId)] }))
    .filter(parent => parent.externalEvents.length);
}
