import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Header } from '@parkhub/parkhub-ui';
import { LinearProgress } from 'react-md';
import moment from 'moment';
import TagManager from 'react-gtm-module';
import { ErrorBoundary, Subheader, TabContainer, TabPanel, NeedsConfirmation, Unassociated, Associated } from '../';
import { signOut, defaultSearchParams, getAuthUser } from '../../utils';
import {
  removeAssociations,
  confirmAssociation,
  removeEvent,
  getEventsAsync,
  saveLastParams
} from '../../redux/actions/events.action';
import { createFeatureAccess } from '@parkhub/parkhub-ui';

import ParkhubLogo from '../../assets/logo.svg';

function Layout(props) {
  const authUser = getAuthUser();
  const access = createFeatureAccess();
  const {
    getEvents,
    allEventsIsLoading,
    allEvents,
    errorReason
  } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [searchParams, setSearchParams] = useState({
    eventName: '',
    landmarkName: '',
    source: '',
    dateFrom: moment(defaultSearchParams.dateFrom),
    dateTo: moment(defaultSearchParams.dateTo)
  });

  useEffect(() => {
    getEvents({
      eventName: '',
      landmarkName: '',
      source: '',
      dateFrom: moment(defaultSearchParams.dateFrom).toISOString(),
      dateTo: moment(defaultSearchParams.dateTo).toISOString()
    });

  }, [getEvents]);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: { 'active_tab': getActiveTab(activeTab) },
      dataLayerName: 'dataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }, [activeTab]);

  function getActiveTab(tab) {
    const tabName = {
      0: 'NeedsConfirmation',
      1: 'Unassociated',
      2: 'Associated'
    };

    return tabName[tab];
  }

  function setTab(index) {
    setActiveTab(index);
  }

  return (
    <>
      <Header
        authUser={authUser}
        access={access}
        signOut={() => signOut()}
        logoSrc={ParkhubLogo}
        logoWidth={333}
        appMenuActive
        logoClick={() => { }}
      >
        <Subheader
          setTab={setTab}
          activeTab={activeTab}
          {...props}
          data={allEvents}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      </Header>
      {allEventsIsLoading && <LinearProgress id="progress" />}
      <main className="wrapper">
        <ErrorBoundary>
          {!allEventsIsLoading &&
            <>
              {!errorReason ?
                <TabContainer activeTab={activeTab}>
                  <TabPanel>
                    {activeTab === 0 &&
                      <NeedsConfirmation {...props} data={allEvents.needsConfirmation} />
                    }
                  </TabPanel>
                  <TabPanel>
                    {activeTab === 1 &&
                      <Unassociated {...props} data={allEvents.unassociated} />
                    }
                  </TabPanel>
                  <TabPanel>
                    {activeTab === 2 &&
                      <Associated {...props} data={allEvents.associated} />
                    }
                  </TabPanel>
                </TabContainer>
                :
                <div className="search-error">
                  <h2 className="no-results">
                    {errorReason}
                  </h2>
                  <img src="cat-cartoon-error.gif" alt="cat computer error" />
                </div>
              }
            </>
          }
        </ErrorBoundary>
      </main>
    </>
  );
}

const mapStateToProps = (state) => {
  return ({
    allEvents: state.allEvents.data,
    allEventsIsLoading: state.allEvents.isLoading,
    allEventsIsError: state.allEvents.isError,
    errorReason: state.allEvents.errorReason,
    params: state.params.data
  });
};

export default connect(mapStateToProps, {
  removeAssociations,
  confirmAssociation,
  removeEvent,
  getEvents: getEventsAsync,
  saveLastParams
})(Layout);
