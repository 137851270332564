import { createRoot } from 'react-dom/client';
import { MessageQueue, Configuration } from 'react-md';
import { Provider } from 'react-redux';
import { App } from './components';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Configuration>
    <MessageQueue id="snackbar">
      <Provider store={store}>
        <App />
      </Provider>
    </MessageQueue>
  </Configuration>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
