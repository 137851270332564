import { configureStore } from '@reduxjs/toolkit';
import { allEventsReducer, paramsReducer } from './reducers/events.reducer';

const store = configureStore({
  reducer: {
    allEvents: allEventsReducer,
    params: paramsReducer
  }
});

export default store;
