import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button, Checkbox, Dialog, DialogContent, DialogFooter, Typography, FontIcon } from 'react-md';
import { PairConnectorArrow, Linked } from '../../assets/react-icons';
import { EventCard, Selectable } from '..';
import { calcTotalPasses, remove, isEmpty } from '../../utils';
import './Expansion.scss';

export default function EventExpansion({
  checkedState,
  indexKey,
  removeEvent,
  removeAssociations,
  onCheck,
  item,
  selectable,
  type
}) {
  const [checked, setChecked] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState([]);

  useEffect(() => {
    if (!isEmpty(checkedState)) {
      const { value, indexKey: checkedStateIndexKey, force } = checkedState;

      if (force) {
        setChecked(value);

      } else if (checkedStateIndexKey === indexKey) {
        setChecked(value);
      }
    }
  }, [checkedState, indexKey]);

  function handleEventClick(data, active) {
    setSelectedEvents(prevState => (
      active ? [data, ...prevState] : remove(prevState, data, 'id')
    ));
  }

  function hideRemoveModal(internalEvent) {
    if (internalEvent) {
      removeAssociations([internalEvent]);
    }

    setDialogVisible(false);
  };

  const renderIcon = () => {
    if (type === 'pair') {
      return (<PairConnectorArrow color="#c4c4c4" height="28" width="28" />);
    } else if (type === 'link') {
      return (<Linked color="#c4c4c4" height="28" width="28" />);
    }
  };

  return (
    <>
      <div className="expansion expanded">
        <div className={cx('expansion-header', { checked: checked })}>
          <div className="center-v">
            {selectable &&
              <Checkbox
                id={`item-${indexKey}`}
                name={`item-${indexKey}[]`}
                label=""
                type="checkbox"
                onChange={(e) => onCheck(e.currentTarget.checked, indexKey, false, item)}
                checked={checked}
              />
            }
          </div>
          <div className="center-v">
            {
              selectedEvents.length > 0 ?
                <>
                  <Button
                    theme="warning"
                    className="remove-button"
                    onClick={() => removeEvent(selectedEvents)}
                  >
                    REMOVE ({selectedEvents.length}) {selectedEvents.length > 1 ? 'EVENTS' : 'EVENT'}
                  </Button>
                </>
                :
                <>
                  <span className="expansion-header-passes">
                    {calcTotalPasses(item.externalEvents)} passes
                  </span>
                  <Button
                    buttonType="icon"
                    className="expansion-header-button remove-events-button"
                    onClick={() => setDialogVisible(true)}
                    title="Unpair Events"
                  >
                    <FontIcon>link_off</FontIcon>
                  </Button>
                </>
            }
          </div>
        </div>
        <div className="expansion-content">
          <>
            <div>
              {item.externalEvents.map((externalEvent, externalKey) => {
                return (
                  <Selectable
                    key={externalKey}
                    selectable
                    data={externalEvent}
                    onClick={handleEventClick}
                  >
                    <EventCard
                      event={externalEvent.eventName}
                      landmark={externalEvent.landmarkName}
                      timestamp={externalEvent.eventTimestamp}
                      source={externalEvent.source}
                      passes={externalEvent.passCount}
                      identifier={externalEvent.eventIdentifier}
                    />
                  </Selectable>
                );
              })}
            </div>
            <div className="pair center">
              {renderIcon()}
            </div>
            <div>
              <EventCard
                live={item.live}
                event={item.eventName}
                eventFrom={item.from}
                landmark={item.landmarkName}
              />
            </div>
          </>
        </div>
      </div>
      <Dialog
        id={`remove-modal-${indexKey}`}
        visible={dialogVisible}
        aria-labelledby="confirm remove pair"
        modal
        onRequestClose={hideRemoveModal}
      >
        <DialogContent>
          <Typography>
            Are you sure you want to remove the association?
          </Typography>
        </DialogContent>
        <DialogFooter>
          <Button
            onClick={() => hideRemoveModal(null)}
            theme="secondary"
          >
            CANCEL
          </Button>
          <Button
            onClick={() => hideRemoveModal(item)}
            theme="warning"
          >
            YES
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

EventExpansion.propTypes = {
  type: 'pair'
};

EventExpansion.propTypes = {
  checkedState: PropTypes.object, // object containing  value, indexKey, force
  indexKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // key to send out with onCheck
  item: PropTypes.object, // data passed in to get the id
  onCheck: PropTypes.func, // function called with value, indexKey, force = false, and item.id, when top level checkbox is checked
  selectable: PropTypes.bool, // show or hide top level checkbox
  type: PropTypes.oneOf(['pair', 'link'])
};
