import React, { Component } from 'react';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {

    this.setState({ hasError: true });

    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="wrapper">
          <div className="search-error">
            <h1 className="no-results">Uh oh, something went wrong!</h1>
            <img src="skate-fail.gif" alt="hipster skater fail" />
            <p>Please refresh the page. If the problem persists, please contact the admin.</p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
