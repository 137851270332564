import { useState } from 'react';
import { Button, Checkbox, useAddMessage } from 'react-md';
import { EventExpansion } from '../';
import { remove, isEmpty, sortByDate } from '../../utils';
import { useStateWithCallbackLazy } from '../../hooks';
import './NeedsConfirmation.scss';

function NeedsConfirmation(props) {
  const addMessage = useAddMessage();
  const {
    data,
    confirmAssociation,
    removeAssociations
  } = props;
  const [associations, setAssociations] = useStateWithCallbackLazy([]);
  const [topCheckedState, setTopCheckedState] = useState(false);
  const [childCheckedState, setChildCheckedState] = useState({});

  function updateAssociations(value, indexKey, force, item) {
    setChildCheckedState({
      value,
      indexKey,
      force
    });

    setAssociations(
      prevState => value ? [item, ...prevState] : remove(prevState, item),
      (nextValue) => setTopCheckedState(nextValue.length >= 1)
    );
  }

  function setIndeterminateState() {
    return (associations.length && associations.length < data.length) || false;
  }

  function handleOnChangeTopSelect(value) {
    const allSelectedList = data.map((item) => item);
    const allDeselectedList = [];

    setChildCheckedState({
      indexKey: null,
      force: true,
      value
    });

    setTopCheckedState(value);
    setAssociations(value ? allSelectedList : allDeselectedList);
  }

  function isDisabled() {
    return associations.length === 0;
  }

  function handleConfirm() {
    confirmAssociation(associations);
    handleOnChangeTopSelect(false);

    addMessage({
      children: `Confirmed ${associations.length} suggested ${associations.length > 1 ? 'pairs' : 'pair'}`,
      action: {
        children: 'UNDO',
        onClick: () => undoConfirm(associations),
        theme: 'primary'
      }
    });
  }

  function undoConfirm(items) {
    removeAssociations(items);
  }

  return (
    <div id="needs-confirmation">
      <div className="wrapper">
        {!isEmpty(data) ?
          <>
            <div className="top-controls">
              <Checkbox
                id="top-control-select"
                name="top-control-select"
                label={`${associations.length} of ${data.length} pairs selected`}
                indeterminate={setIndeterminateState()}
                checked={topCheckedState}
                onChange={(e) => handleOnChangeTopSelect(e.currentTarget.checked)}
              />
              <Button
                id="confirm-button"
                disabled={isDisabled()}
                onClick={handleConfirm}
                className="confirm-button"
                theme="primary"
                themeType="contained"
              >
                Confirm ({associations.length}) Pairs
              </Button>

            </div>
            {
              sortByDate(data, 'from').map((internalEvent, internalKey) => {
                return (
                  <EventExpansion
                    key={internalKey}
                    item={internalEvent}
                    checkedState={childCheckedState}
                    onCheck={updateAssociations}
                    indexKey={internalKey}
                    selectable
                    type="pair"
                    {...props}
                  />
                );
              })
            }
          </>
          :
          <div className="search-error">
            <h2>Nothing to confirm. Carry on my wayward son!</h2>
          </div>
        }
      </div>
    </div>
  );
}

export default NeedsConfirmation;
