import { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useStateWithCallbackLazy } from '../../hooks';
import { CircleCheckMark } from '../../assets/react-icons';
import cx from 'classnames';

export function Selectable({
  selectable,
  inactive,
  reset,
  onClick,
  data,
  children
}) {
  const [selectableState, setSelectableState] = useState(true);
  const [activeState, setActiveState] = useStateWithCallbackLazy(false);
  const [inactiveState, setInactiveState] = useState(false);

  useEffect(() => {
    setSelectableState(selectable);
  }, [selectable]);

  useEffect(() => {
    setInactiveState(inactive);
    setSelectableState(!inactive);
  }, [inactive]);

  useEffect(() => {
    if (reset) {
      setActiveState(false);
      setSelectableState(false);
    }
  }, [reset, setActiveState]);

  function handleClick() {
    if (selectable && !inactive) {
      setActiveState(!activeState);

      if (onClick) {
        onClick(data, !activeState);
      }
    }
  }

  return (
    <div
      onClick={handleClick}
      className={cx(
        'selectable-event-card',
        { active: selectableState && activeState },
        { selectable: selectableState },
        { inactive: inactiveState }
      )}
    >
      <span className="selectable-event-icon">
        <CircleCheckMark color="#2D9CDB" width={25} />
      </span>
      {children}
    </div>
  );
}

Selectable.propTypes = {
  children: PropTypes.node,
  data: PropTypes.any,
  inactive: PropTypes.bool,
  onClick: PropTypes.func,
  selectable: PropTypes.bool
};

Selectable.defaultProps = {
  selectable: true
};
