import { useCallback, useEffect, useRef, useState } from 'react';
import { SessionDialog } from '@parkhub/parkhub-ui';
import { Layout } from '../';
import { checkAuth, getSessionTime, refreshSession, signOut } from '../../utils';

function App() {
  const checkTime = useRef(false);
  const [sessionModalVisible, setSessionModalVisible] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  function hide() {
    setSessionModalVisible(false);
  }

  function show() {
    setSessionModalVisible(true);
  }

  const startTimeout = useCallback(
    () => {
      refreshSession()
        .then(() => {
          clearInterval(checkTime.current);

          const checkSessionTimeout = () => {
            if (!checkAuth()) {
              window.location.replace(`${process.env.REACT_APP_SUITE_URL}signin?redirectUrl=${window.location.href}`);

            } else {
              const minutes = getSessionTime();

              if (minutes > 1440) {
                setIsAuthorized(false);
                setSessionModalVisible(false);
                signOut('?expired=true');

              } else if (minutes > 1438 && minutes < 1440) {
                show();
              }
            }
          };

          checkTime.current = setInterval(checkSessionTimeout, 10000);
        }).catch(error => console.error(error));
    }, []
  );

  const checkLoggedIn = useCallback(
    () => {
      if (!checkAuth()) {
        window.location.replace(`${process.env.REACT_APP_SUITE_URL}signin?redirectUrl=${window.location.href}`);

      } else {
        setIsAuthorized(true);
        startTimeout();
      }
    }, [startTimeout],
  );

  useEffect(() => {
    checkLoggedIn();

    return () => {
      clearInterval(checkTime.current);
    };
  }, [checkLoggedIn]);

  return (
    <>
      {isAuthorized &&
        <>
          <Layout />
          <SessionDialog
            onHide={hide}
            visible={sessionModalVisible}
            onContinue={startTimeout}
            onTimeExpire={() => signOut('?expired=true')}
            onSignOut={signOut}
          />
        </>
      }
    </>
  );
};

export default App;
