import * as types from '../actions/ActionTypes';

const INITIAL_STATE = {
  isLoading: true,
  isError: null,
  data: [],
  errorReason: null
};

const INITIAL_PARAMS = {

};

export function allEventsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {

    case types.GET_EVENTS_PENDING: {
      return {
        ...state,
        isLoading: true,
        isError: null,
        errorReason: null
      };
    }

    case types.GET_EVENTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        isError: null,
        errorReason: null
      };

    case types.GET_EVENTS_REJECTED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
        errorReason: 'Possible network error.'
      };

    case types.GET_EVENTS_ABORTED:
      return {
        ...state,
        data: [],
        isError: action.payload,
        isLoading: false,
        errorReason: action.payload.code === 'ECONNABORTED' ? 'She cannae take anymore, Captain! Please try narrowing your search.' : 'Uh oh! Network error'
      };

    default: return state;
  }
}

// TODO: is this necessary?
export function paramsReducer(state = INITIAL_PARAMS, action) {
  switch (action.type) {
    case types.SAVE_PARAMS: {
      return {
        ...state,
        data: action.payload
      };
    }

    default: return state;
  }
}
