import { EventExpansion } from '../';
import { isEmpty, sortByDate } from '../../utils';
import './Associated.scss';

export default function Associated({
  data,
  ...props
}) {

  return (
    <div id="associated">
      <div className="wrapper">
        {!isEmpty(data) ?
          <>
            {
              sortByDate(data, 'from').map((internalEvent, internalKey) => {
                return (
                  <EventExpansion
                    key={internalKey}
                    item={internalEvent}
                    indexKey={internalKey}
                    type="link"
                    {...props}
                  />
                );
              })
            }
          </>
          :
          <div className="search-error">
            <h2>Nothing associated, fam</h2>
          </div>
        }
      </div>
    </div>
  );
}
