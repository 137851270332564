import DatePicker from 'react-datepicker';
import { Card, Button, TextField, FontIcon } from 'react-md';
import moment from 'moment';
import { defaultSearchParams } from '../../utils';
import './SearchFilter.scss';

export function SearchFilter({
  getEvents,
  setVisible,
  searchParams,
  setSearchParams
}) {

  function handleChangeFrom(date) {
    setSearchParams(prevState => ({
      ...prevState,
      dateFrom: moment(date)
    }));
  }

  function handleChangeTo(date) {
    setSearchParams(prevState => ({
      ...prevState,
      dateTo: moment(date)
    }));
  }

  function handleInputChange(event) {
    setSearchParams(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  }

  function handleOnSubmit(event) {
    event.preventDefault();
    const { eventName, landmarkName, source, dateFrom, dateTo } = searchParams;

    const params = {
      eventName: eventName,
      landmarkName: landmarkName,
      dateFrom: moment(dateFrom).toISOString(),
      dateTo: moment(dateTo).toISOString(),
      source: source
    };

    getEvents(params);
    setVisible();
  }

  function isDisabled() {
    const { dateFrom, dateTo } = searchParams;
    return dateFrom && dateTo ? false : true;
  }

  function resetDate() {
    setSearchParams({
      eventName: '',
      landmarkName: '',
      source: '',
      dateFrom: moment(defaultSearchParams.dateFrom),
      dateTo: moment(defaultSearchParams.dateTo)
    });

    getEvents(defaultSearchParams);
    setVisible();
  }

  const { dateFrom, dateTo, source, landmarkName, eventName } = searchParams;

  return (
    <Card className="search-filter">
      <form onSubmit={event => handleOnSubmit(event)} className="search-form">
        <div className="form-group">
          <div className="form-group-icon">
            <FontIcon>search</FontIcon>
          </div>
          <TextField
            id="source"
            onChange={handleInputChange}
            name="source"
            label="Integration Partner"
            value={source}
            theme="underline"
          />
        </div>
        <div className="form-group">
          <div className="form-group-icon">
            <FontIcon>search</FontIcon>
          </div>
          <TextField
            id="eventName"
            onChange={handleInputChange}
            name="eventName"
            label="Event name"
            value={eventName}
            theme="underline"
          />
        </div>
        <div className="form-group">
          <div className="form-group-icon">
            <FontIcon>search</FontIcon>
          </div>
          <TextField
            id="landmarkName"
            onChange={handleInputChange}
            name="landmarkName"
            label="Landmark name"
            value={landmarkName}
            theme="underline"
          />
        </div>
        <div className="form-group">
          <div className="form-group-icon">
            <FontIcon>event</FontIcon>
          </div>
          <div className="form-group-section">
            <div className="input-range-container">
              <div>
                <DatePicker
                  selected={dateFrom.toDate()}
                  onChange={handleChangeFrom}
                />
              </div>
              <div className="to center">to</div>
              <div>
                <DatePicker
                  selected={dateTo.toDate()}
                  onChange={handleChangeTo}
                />
              </div>
            </div>
            <div className="right">
              <Button
                className="reset-button"
                onClick={resetDate}
                theme="secondary"
                themeType="contained"
              >
                Reset
              </Button>
            </div>
          </div>
        </div>
        {
          isDisabled() &&
          <div>
            <span className="error-message">Date range is required</span>
          </div>
        }
        <Button
          disabled={isDisabled()}
          theme="primary"
          type="submit"
          themeType="contained"
        >
          Apply
        </Button>
      </form>
    </Card>
  );
}

export default SearchFilter;
