import { Card, CardContent } from 'react-md';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
export default function EventCard({
  event,
  eventFrom,
  identifier,
  landmark,
  live,
  passes,
  source,
  timestamp
}) {
  function makeTimeReadable(ISOtime) {
    return moment(ISOtime).format('MMMM Do YYYY h:mm a');
  }

  return (
    <Card className="event-card">
      <CardContent>
        {event &&
          <h5>{event}</h5>
        }
        {typeof live !== 'undefined' && <p><span className={cx('indicator', { 'indicator-active': live })} /> {live ? 'Active' : 'Inactive'} Event</p>}
        {identifier && <p>{identifier}</p>}
        {source && <p className="event-source">{source}</p>}
        {timestamp && <p>{makeTimeReadable(timestamp)}</p>}
        {landmark && <p>{landmark}</p>}
        {eventFrom && <p>{makeTimeReadable(eventFrom)}</p>}
        {passes && <span className="passes">{passes} passes</span>}
      </CardContent>
    </Card>
  );
}

EventCard.propTypes = {
  event: PropTypes.string,
  eventFrom: PropTypes.string,
  identifier: PropTypes.string,
  landmark: PropTypes.string,
  live: PropTypes.bool,
  passes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  source: PropTypes.string,
  timestamp: PropTypes.any
};
