import PropTypes from 'prop-types';

export function TabPanel(props) {
  const { children, id, activeState } = props;

  return (
    <div id={id ? id : undefined} className={`tab${activeState ? ' active' : ''}`}>
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node
};
