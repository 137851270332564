import { useEffect, useState, useRef } from 'react';
import { SearchFilter } from '..';
import { Button, TuneSVGIcon } from 'react-md';
import cx from 'classnames';
import './Subheader.scss';

export function Subheader({
  setTab,
  activeTab,
  data,
  getEvents,
  searchParams,
  setSearchParams
}) {
  const [showSearch, setShowSearch] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  function handleClickOutside(event) {
    if (!(wrapperRef?.current?.contains(event.target) || event?.target?.className?.baseVal?.includes('rmd-icon rmd-icon--svg'))) {
      setShowSearch(false);
    }
  }

  function toggleSearch() {
    setShowSearch(prevState => !prevState);
  }

  const searchFilterProps = {
    setTab,
    activeTab,
    data,
    getEvents,
    searchParams,
    setSearchParams
  };

  return (
    <nav className="subheader">
      <div className="subheader-menu">
        <div className={`link-container active-${activeTab}`}>
          <div onClick={() => setTab(0)} className={cx('subheader-menu-item', { active: activeTab === 0 })}>
            Needs Confirmation ({data?.needsConfirmation?.length})
            <span className="bottom-border" />
          </div>
          <div onClick={() => setTab(1)} className={cx('subheader-menu-item', { active: activeTab === 1 })}>
            Unassociated ({data?.unassociated?.externalEvents.length})
            <span className="bottom-border" />
          </div>
          <div onClick={() => setTab(2)} className={cx('subheader-menu-item', { active: activeTab === 2 })}>
            Associated
            <span className="bottom-border" />
          </div>
        </div>
      </div>
      <div>
        <Button
          id="toggle-search-button"
          buttonType="icon"
          theme="secondary"
          className={cx('subheader-action-button', { 'toggle-active': showSearch })}
          onClick={() => toggleSearch()}
        >
          <TuneSVGIcon />
        </Button>
      </div>
      <div ref={wrapperRef} className={`search-filter-container ${showSearch ? 'active' : ''}`}>
        <SearchFilter setVisible={toggleSearch} {...searchFilterProps} />
      </div>
    </nav>
  );
}

export default Subheader;
